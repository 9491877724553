<div class="about-site-view-container">
    <app-content-block>
        <p>
        I created this site to showcase my ability to work with different technologies on a functioning and practical project that anyone 
        could see without having to look at any code.  All the code for this site lives in 
        a public monorepo on <a mat-button color="accent" href="https://www.github.com/brennanmcfarland/portfolio-site" style="margin: -10px -10px;">GitHub</a>.  
        I'll be making changes to the site's structure over time so I'll try and keep this part up to date, but for now at least everything's 
        hosted on AWS.  The frontend is an Angular Single Page Application hosted on AWS Amplify.  It makes calls to my REST API Gateway, which uses 
        Amazon API Gateway.
        </p>
        <p>
        The Gateway redirects to the backend microservice architecture.  Right now there's only one microservice, which retrieves 
        data for my list of projects.  I realize that probably should just be part of the fronend, but I wanted to have at least one service running and 
        didn't have time to write another at the time.  The projects service itself is written in Java and pulls its data from a MySQL database.  
        The service lives on an EC2 instance, because those are cheap, really cheap because it's preemptible, which means Amazon can knock it 
        offline temporarily if they run out of regular instances, so it'll probably be unavailable ~5% of the time.  Plus all the times I break it while 
        pushing updates and fixes.  Which leads me to...
        </p>
        <p>
        <app-live-indicator></app-live-indicator>
        This handy little widget will tell you if my site is actually able to hit its backend API.  When the site is first loaded, it sends a request to 
        the API as a healthcheck of sorts.  If it gets a successful response, hooray!  The API is (not necessarily working properly but) at the very least 
        accessible.  If not, that's ok and the site should still (hopefully for the most part at least) work: the frontend includes a data cache that, while 
        probably not the most up to date, should at least return something reasonable to keep the site functioning until the API can once again be reached.
        </p>
        <p>
        There's a whole lot else I'd love to do, like add more services and containerize everything.  We'll see how that goes!  In the meantime though, it's 
        got all the basics: frontend, microservices backend, API, database, etc.
        </p>
    </app-content-block>
</div>