<div class="aboutmedb-view-container">
    <app-content-block>
        A custom keyword search engine for things about me pulling in data from across my 
        professional profile.  Its purpose is mainly to show my capabilities in building something across
        the application stack, including database architecture, backend, frontend, deployment, etc. 
        It's currently a work in progress, able to successfully display data from the database but I haven't 
        yet gotten around to deploying it, so I've disabled it for now.  But you can still check out the code 
        on my <a mat-button color="accent" href="https://github.com/brennanmcfarland/portfolio-site/tree/main/aboutmedb" style="margin: -10px -10px;">GitHub</a> 
        if you're curious!
    </app-content-block>
    <!-- <app-aboutmedb-app></app-aboutmedb-app> -->
</div>