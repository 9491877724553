import { Component } from '@angular/core';

@Component({
  selector: 'app-aboutmedb',
  templateUrl: './aboutmedb.component.html',
  styleUrls: ['./aboutmedb.component.scss']
})
export class AboutmedbComponent {

}
