<mat-toolbar class="header-toolbar">
    <button mat-button routerLink="home" color="accent" class="site-title"><h1>Brennan McFarland</h1></button>
    <span class="menu-button-group">
        <button mat-button routerLink="home" routerLinkActive="active-tab">Home</button>
        <button mat-button routerLink="projects" routerLinkActive="active-tab">Projects</button>
        <button mat-button routerLink="aboutsite" routerLinkActive="active-tab">This Site</button>
    </span>
    <span>
        <app-live-indicator></app-live-indicator>
    </span>
</mat-toolbar>
<router-outlet></router-outlet>
<footer>Requisite third party attributions, because everyone deserves credit for their work: <div>Icons made by <a href="https://www.flaticon.com/authors/good-ware" title="Good Ware">Good Ware</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div></footer>