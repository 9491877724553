<div class="home-view-container">
    <div class="profile-header">
        <div class="profile-image-container">
            <img src="../../assets/me.png" class="profile-image">
        </div>
        <div class="home-links">
            <!-- TODO: make these components -->
            <div class="home-link-item"><a mat-stroked-button href="https://www.linkedin.com/in/brennanmcfarland" class="home-link" style="padding: 0 25px;"><b>LinkedIn</b></a><div class="home-link-footer">linkedin.com/in/brennanmcfarland</div></div>
            <div class="home-link-item"><a mat-stroked-button href="https://www.github.com/brennanmcfarland" class="home-link" style="padding: 0 20px;"><b>GitHub</b></a><div class="home-link-footer">github.com/brennanmcfarland</div></div>
            <div class="home-link-item"><a mat-stroked-button href="mailto:theotherbrennanmcfarland@gmail.com" class="home-link"><b>Email</b></a><div class="home-link-footer">theotherbrennanmcfarland@gmail.com<br>For my professional email, please see my resume</div></div>
        </div>
    </div>
    <app-content-block>
        <p>
            Welcome to my site!  Its main purpose is as a hub for my professional profile to showcase my abilities 
            as a Developer/Software Engineer, but also as a place for me to experiment and make new and fun things 
            in a place where people can see how they work without digging into the source code.  It's currently very 
            much a work in progress, but I'll try to keep adding new features and to keep it up to date over time 
            (we'll see how that goes)!
        </p>
    </app-content-block>
    <app-content-block>
        <p>
            Since this site is itself partly a demonstration of my ability to work across the stack, everything you see 
            is custom-coded, without any low-code or no-code solutions being used.  The code for this site is hosted in 
            a public monorepo on <a mat-button color="accent" href="https://www.github.com/brennanmcfarland/portfolio-site" style="margin: -10px -10px;">GitHub</a>.  
            I used Angular for the frontend and will be using a variety of languages in a service oriented architecture 
            for the backend, piped through Amazon API Gateway.  It's all cloud-hosted on AWS, with Amplify for the frontend and backend services 
            hosted on EC2 instances.
        </p>
    </app-content-block>
</div>
