<div class="background-image-container"></div>
<div class="view-title"><h1>Projects</h1></div>
<div class="projects-view-container">
    <app-content-block>
        My GitHub has a lot of cruft in it, so I thought it'd be helpful to enumerate some of my most interesting projects over the years.  
        Unless otherwise marked each one of these is a personal side project, and I only included projects that were "original" (eg, I did 
        not include school projects where everyone coded the same thing).  This list is provided via an API-gated backend microservice 
        implemented with the Java Spring Framework and SQL for the database.
    </app-content-block>
    <app-project *ngFor="let project of projects$ | async" title="{{project.displayName}}" link="{{project.sourceUrl}}" [isSchoolProject]="project.isSchoolProject">
        {{ project.description }}
    </app-project>
    <!-- TODO: empty/failure state -->
</div>