<app-content-block>
    <div class="project-header">
        <div>
            <h1>{{ title }}</h1>
        </div>
        <div *ngIf="isSchoolProject"> <i>school project</i></div> <!-- TODO: make this look nice -->
        <div class="project-link">
            <a mat-button color="accent" href="{{link}}">{{ link }}</a>
        </div>
    </div>
    <div class="project-description">
        <ng-content></ng-content>
    </div>
</app-content-block>